<template>
  <v-theme-provider dark>
    <section id="consulting-description">
      <base-img
        :min-height="minHeight"
        :max-height="350"
        :gradient="gradient"
        :src="require('@/assets/consulting.jpg')"
        color="#45516b"
        flat
        max-width="100%"
        tile
      >
        <v-container class="fill-height px-4 py-4">
          <v-responsive
            class="d-flex align-center mx-auto"
            height="100%"
            max-width="700"
            width="100%"
          >
            <base-heading
              size="text-h3"
              title="HVS - CONSULTING"
              weight="medium"
            />
            <base-body>
              Hedge Vision Systems' consulting services include partnering with
              our clients to understand their current processes, challenges, and
              future roadmaps, and propose strategies/solutions to solve their
              challenges, improve performance and create alpha.
            </base-body>
          </v-responsive>
        </v-container>
      </base-img>
    </section>
  </v-theme-provider>
</template>

<script>
// Components
import { HexToRGBA, RGBAtoCSS } from "vuetify/lib/util/colorUtils";

export default {
  name: "ProductDescription",

  metaInfo() {
    return {
      changed: (meta) => (this.title = meta.titleChunk.toUpperCase()),
    };
  },

  provide: {
    heading: { align: "center" },
  },

  data: () => ({
    title: "",
  }),

  computed: {
    gradient() {
      const color = `${this.$vuetify.theme.themes.light.secondary}CC`;
      const overlay = RGBAtoCSS(HexToRGBA(color));

      return `to top, ${overlay}, ${overlay}`;
    },
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? "60vh" : "30vh";
      return `calc(${height} - ${this.$vuetify.application.top}px)`;
    },
  },
};
</script>
<style>
.video-div {
  position: relative;
  width: 100%;
  max-height: 500px;
  overflow: hidden;
}

.video-div video {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  height: auto;
}

.video-div .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 255, 0.5);
}
</style>
